<template>
  <div class="w-100">
    <v-toolbar flat height="60" class="w-100 px-6">
      <div style="font-size: 17px">
        <router-link class="roledetail-listlink" to="/role">
          権限マスタ
        </router-link>
        <s-icon class="mx-3" icon="feather-chevron-right" size="xl" />
        <span class="roledetail-rolename">
          {{ roleName }}
        </span>
      </div>
      <v-spacer />
      <div>
        <v-btn
          color="primary"
          dark
          depressed
          :disabled="!editable"
          :loading="loading"
          @click.native.stop="onClickUpdate"
        >
          <i class="ti-save mr-2" />
          保存
        </v-btn>
      </div>
    </v-toolbar>

    <role-abilities-table ref="form" :items="abilities" :readonly="!editable" />
  </div>
</template>

<style scoped>
.roledetail-listlink {
  color: black !important;
}
.roledetail-listlink:hover {
  text-decoration: underline solid black !important;
}
.roledetail-rolename {
  color: rgb(50, 50, 100);
  font-weight: 600;
}
</style>

<script>
import RoleAbilitiesTable from './components/RoleAbilitiesTable'
import Snackbar from 'Helpers/snackbar/index'
import abilitySettings from 'Helpers/role/data/index'

export default {
  components: {
    RoleAbilitiesTable,
  },
  data() {
    return {
      loading: false,
      abilities: [
        {
          resource: '',
          operation: '',
          level: 0,
          text: {
            operation: '',
            resource: '',
          }
        },
      ],
      editable: false,
      roleName: '',
    }
  },
  watch: {
    '$route.params.id': function () {
      this.getAbilities()
    },
  },
  mounted() {
    this.getRole()
    this.getAbilities()
  },
  methods: {
    setLoading(v) {
      this.loading = v
    },
    onClickUpdate() {
      if (!this.$refs.form.validate()) {
        Snackbar.error('入力エラー')
        return
      }
      this.updateAbilities()
    },
    updateAbilities() {
      this.setLoading(true)
      const roleId = this.$route.params.id
      const items = this.abilities
      this.$store
        .dispatch('putAbilities', { roleId, items })
        .then((response) => {
          if (response.status == 'success') {
            this.getAbilities()
            this.setLoading(false)
            Snackbar.success('更新しました')
          } else {
            this.onUpdateAbilitiesError(response)
            this.setLoading(false)
          }
        })
    },
    onUpdateAbilitiesError(response) {
      Snackbar.error(
        `エラーが発生しました.\n${response.error}:${response.message}`
      )
    },
    getRole() {
      const roleId = this.$route.params.id
      this.$store.dispatch('getRole', { roleId }).then((response) => {
        if (response.status == 'success') {
          this.roleName = response.item.name
          this.editable = response.item.organization_id ? true : false
        } else {
          Snackbar.error('エラーが発生しました')
        }
      })
    },
    getAbilities() {
      const roleId = this.$route.params.id
      this.$store.dispatch('getAbilities', roleId).then((response) => {
        if (response.status == 'success') {
          this.setAbilities(response.items)
        } else {
          Snackbar.error('エラーが発生しました')
        }
      })
    },
    setAbilities(items) {
      this.abilities.length = 0

      const itemsByAbilityKey = Object.fromEntries(
        items.map((obj) => [obj.resource + ':' + obj.operation, obj])
      )
      const displaySetting = this.$store.getters.displaySetting
      const settingPaidByCustomer = this.$store.getters.settingPaidByCustomer
      const approveLevel = this.$store.getters.approveLevel
      const payConfirmLevel = this.$store.getters.payConfirmLevel
      const freeeSeparateApproval = this.$store.getters.freeeSeparateApproval

      const masterKeys = [
        'account_title',
        'department',
        'tax',
        'project',
        'segment',
        'vendor',
        'item',
        'tag',
        'walletable',
      ]

      let level, maxLevel, item
      let resource, operation, resourceText, operationText, display

      for (let resourceSetting of abilitySettings) {
        resource = resourceSetting.key
        resourceText = resourceSetting.text

        // check displaySetting (always show department)
        if (
          masterKeys.includes(resource) &&
          resource !== 'department' &&
          !displaySetting[resource]
        ) {
          continue
        }
        // check transfer_fee
        if (resource === 'transfer_fee' && !settingPaidByCustomer) {
          continue
        }

        for (let operationSetting of resourceSetting.operations) {
          // get ability level
          operation = operationSetting.key
          item = itemsByAbilityKey[resource + ':' + operation]
          if (item === undefined) {
            level = 0
          } else {
            level = item.level
          }

          // check max ability level
          maxLevel = Math.max(...operationSetting.options.map((op) => op.value))
          if (level > maxLevel) {
            level = maxLevel
          }

          // set display name of operation
          operationText = operationSetting.text
          if (typeof operationText === 'function') {
            if (
              resource === 'invoice' &&
              operationSetting.key.includes('approve')
            ) {
              operationText = operationText(approveLevel)
            } else if (
              resource === 'payment' &&
              operationSetting.key.includes('approve')
            ) {
              operationText = operationText(payConfirmLevel)
            } else {
              operationText = ''
            }
          }

          // check whether or not to display
          display = true
          if (typeof operationSetting.display === 'function') {
            if (operationSetting.key === 'approve_freee') {
              display = operationSetting.display(freeeSeparateApproval)
            } else if (
              resource === 'invoice' &&
              operationSetting.key.includes('approve')
            ) {
              display = operationSetting.display(approveLevel)
            } else if (
              resource === 'payment' &&
              operationSetting.key.includes('approve')
            ) {
              display = operationSetting.display(payConfirmLevel)
            }
          }
          if (!display) {
            continue
          }

          this.abilities.push({
            resource: resource,
            operation: operation,
            level: level,
            maxLevel: maxLevel,
            depends: operationSetting.depends ? operationSetting.depends : [],
            options: operationSetting.options,
            text: {
              resource: resourceText,
              operation: operationText,
            },
          })
        }
      }
    },
  },
}
</script>
