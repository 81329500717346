export default {
  key: 'settings',
  text: '管理者設定',
  operations: [
    {
      key: 'read',
      text: '全ての操作',
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
  ],
}
