export default {
  key: 'account',
  text: 'アカウント',
  operations: [
    {
      key: 'login',
      text: 'ログイン',
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
  ],
}
