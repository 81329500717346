export default {
  key: 'payment',
  text: '支払',
  operations: [
    {
      key: 'read',
      text: '閲覧',
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'update',
      text: '更新',
      depends: ['payment:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'export_journal',
      text: '仕訳エクスポート',
      depends: ['payment:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'export_table',
      text: '一覧エクスポート',
      depends: ['payment:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'approve1',
      text: (payConfirmLevel) => `確認${payConfirmLevel - 2}`,
      display: (payConfirmLevel) => payConfirmLevel -2 > 0,
      depends: ['payment:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'approve2',
      text: (payConfirmLevel) => `確認${payConfirmLevel - 1}`,
      display: (payConfirmLevel) => payConfirmLevel -1 > 0,
      depends: ['payment:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'approve3',
      text: (payConfirmLevel) => `確認${payConfirmLevel}`,
      display: (payConfirmLevel) => payConfirmLevel > 0,
      depends: ['payment:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'approve4',
      text: '支払連携',
      depends: ['payment:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'approve5',
      text: '支払済/支払データ出力',
      depends: ['payment:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
  ],
}
