export default {
  key: 'company',
  text: '請求元｜学習データ',
  operations: [
    {
      key: 'read',
      text: '閲覧',
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'update',
      text: '更新',
      depends: ['company:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'delete',
      text: '削除',
      depends: ['company:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'export',
      text: 'エクスポート',
      depends: ['company:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
  ],
}
