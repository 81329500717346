export default {
  key: 'invoice',
  text: '請求書',
  operations: [
    {
      key: 'upload',
      text: 'アップロード',
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'read',
      text: '閲覧',
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'update',
      text: '更新',
      depends: ['invoice:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'update_note',
      text: '更新/備考',
      depends: ['invoice:read', 'invoice:update'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'delete',
      text: '削除',
      depends: ['invoice:read', 'invoice:update'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'export_journal',
      text: '仕訳エクスポート',
      depends: ['invoice:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'export_table',
      text: '一覧エクスポート',
      depends: ['invoice:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'approve1',
      text: (approveLevel) => `承認${approveLevel - 4}`,
      display: (approveLevel) => approveLevel - 4 > 0,
      depends: ['invoice:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'approve2',
      text: (approveLevel) => `承認${approveLevel - 3}`,
      display: (approveLevel) => approveLevel - 3 > 0,
      depends: ['invoice:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'approve3',
      text: (approveLevel) => `承認${approveLevel - 2}`,
      display: (approveLevel) => approveLevel - 2 > 0,
      depends: ['invoice:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'approve4',
      text: (approveLevel) => `承認${approveLevel - 1}`,
      display: (approveLevel) => approveLevel - 1 > 0,
      depends: ['invoice:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'approve5',
      text: '最終承認',
      depends: ['invoice:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
    {
      key: 'approve_freee',
      text: 'freee承認',
      display: (freeeSeparateApproval) => freeeSeparateApproval,
      depends: ['invoice:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '自分のデータ',
        },
        {
          value: 2,
          text: '所属部門',
        },
        {
          value: 3,
          text: '全て',
        },
      ],
    },
  ],
}
