export default {
  key: 'report',
  text: 'レポート',
  operations: [
    {
      key: 'read',
      text: '閲覧',
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'export',
      text: 'エクスポート',
      depends: ['report:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
  ],
}
