// Settings of All Abilities
import accountAbilities from './account'
import invoiceAbilities from './invoice'
import paymentAbilities from './payment'
import reportAbilities from './report'
import inboxAbilities from './inbox'
import settingsAbilities from './settings'
import companyAbilities from './company'
import accountTitleAbilities from './account_title'
import departmentAbilities from './department'
import taxAbilities from './tax'
import vendorAbilities from './vendor'
import projectAbilities from './project'
import segmentAbilities from './segment'
import walletableAbilities from './walletable'
import itemAbilities from './item'
import tagAbilities from './tag'
import transferFeeAbilities from './transfer_fee'
import emailAbilities from './email'

const settings = [
  accountAbilities,
  emailAbilities,
  inboxAbilities,
  invoiceAbilities,
  paymentAbilities,
  reportAbilities,
  settingsAbilities,
  accountTitleAbilities,
  departmentAbilities,
  vendorAbilities,
  projectAbilities,
  segmentAbilities,
  itemAbilities,
  tagAbilities,
  walletableAbilities,
  taxAbilities,
  transferFeeAbilities,
  companyAbilities,
]

export default settings
