var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","wrap":"","pt-3":"","pl-12":"","pr-12":""}},[_c('div',{staticClass:"w-100"},[_c('v-spacer'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"pk","fixed-header":"","height":"calc(100vh - 190px)","items-per-page":-1,"hide-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('span',[_vm._v("\n              "+_vm._s(item.text.resource)+"\n            ")])]),_c('td',[_c('span',[_vm._v("\n              "+_vm._s(item.text.operation)+"\n            ")])]),_c('td',[_c('v-btn-toggle',{attrs:{"mandatory":"","dense":"","color":"blue","readonly":_vm.readonly},model:{value:(item.level),callback:function ($$v) {_vm.$set(item, "level", $$v)},expression:"item.level"}},[_vm._l((item.options),function(option,index){return [_c('v-btn',{key:((item.pk) + "-" + index),attrs:{"small":"","outlined":"","disabled":_vm.checkDisabled(
                      option.value,
                      item.level,
                      item.maxLevel,
                      item.depends
                    ),"value":option.value},domProps:{"textContent":_vm._s(option.text)}})]})],2)],1)])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"info","icon":"warning"},slot:"no-results"},[_vm._v("\n        "+_vm._s(_vm.$t('message.noResult'))+"\n      ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }