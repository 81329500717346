<template>
  <v-layout row wrap pt-3 pl-12 pr-12>
    <div class="w-100">
      <v-spacer />

      <!-- table -->
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        item-key="pk"
        fixed-header
        height="calc(100vh - 190px)"
        :items-per-page="-1"
        hide-footer
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <span>
                {{ item.text.resource }}
              </span>
            </td>
            <td>
              <span>
                {{ item.text.operation }}
              </span>
            </td>
            <td>
              <v-btn-toggle
                v-model="item.level"
                mandatory
                dense
                color="blue"
                :readonly="readonly"
              >
                <template v-for="(option, index) in item.options">
                  <v-btn
                    :key="`${item.pk}-${index}`"
                    small
                    outlined
                    :disabled="
                      checkDisabled(
                        option.value,
                        item.level,
                        item.maxLevel,
                        item.depends
                      )
                    "
                    :value="option.value"
                    v-text="option.text"
                  />
                </template>
              </v-btn-toggle>
            </td>
          </tr>
        </template>

        <v-alert slot="no-results" :value="true" color="info" icon="warning">
          {{ $t('message.noResult') }}
        </v-alert>
      </v-data-table>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          text: 'メニュー',
          value: 'resource',
          sortable: false,
          width: '25%',
        },
        {
          text: '操作',
          value: 'operation',
          sortable: false,
          width: '25%',
        },
        {
          text: '権限',
          value: 'level',
          sortable: false,
          width: '50%',
        },
      ],
    }
  },
  computed: {
    itemsByKey: function () {
      try {
        return Object.fromEntries(
          this.items.map((obj) => [
            obj.resource + ':' + obj.operation,
            { level: obj.level, maxLevel: obj.maxLevel },
          ])
        )
      } catch (err) {
        return {}
        console.log(err)
      }
    },
  },
  methods: {
    validate() {
      const isInvalid = this.items.some((obj) => {
        return !this.checkValidate(obj.level, obj.maxLevel, obj.depends)
      })
      return !isInvalid
    },
    deepCopy(obj) {
      return Object.assign({}, JSON.parse(JSON.stringify(obj)))
    },
    checkDisabled(optionLevel, itemLevel, itemMaxLevel, dependKeys) {
      try {
        if (this.readonly && optionLevel !== itemLevel) {
          return true
        }
        if (!dependKeys || dependKeys.length == 0) {
          return false
        }
        const dependentLevel = this.getDependentLevel(itemMaxLevel, dependKeys)
        return optionLevel > dependentLevel
      } catch (err) {
        console.log(err)
        return true
      }
    },
    checkValidate(itemLevel, itemMaxLevel, dependKeys) {
      try {
        const dependentLevel = this.getDependentLevel(itemMaxLevel, dependKeys)
        return itemLevel <= dependentLevel
      } catch (err) {
        console.log(err)
        return true
      }
    },
    getDependentLevel(itemMaxLevel, dependKeys) {
      try {
        if (!dependKeys) {
          return itemMaxLevel
        }
        let level
        let dependentLevel = itemMaxLevel
        dependKeys.forEach((key) => {
          level = this.getFormLevel(key)
          if (level < dependentLevel) {
            dependentLevel = level
          }
        })
        return dependentLevel
      } catch (err) {
        console.log(err)
        return itemMaxLevel
      }
    },
    getFormLevel(key) {
      const obj = this.itemsByKey[key]
      if (obj.level && obj.level == obj.maxLevel) {
        return 3
      } else if (obj.level && obj.level < obj.maxLevel) {
        return obj.level
      } else {
        return 0
      }
    },
  },
}
</script>
