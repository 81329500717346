export default {
  key: 'inbox',
  text: '回収',
  operations: [
    {
      key: 'read',
      text: '閲覧・更新・削除',
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '担当取引先のみ',
        },
        {
          value: 2,
          text: '所属部門のみ',
        },
        {
          value: 3,
          text: '所属部門および担当取引先',
        },
        {
          value: 4,
          text: '全て',
        },
      ],
    },
    {
      key: 'delete_customer',
      text: '削除/取引先',
      depends: ['inbox:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'approve1',
      text: '承認1',
      depends: ['inbox:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'approve2',
      text: '承認2',
      depends: ['inbox:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'approve3',
      text: '承認3',
      depends: ['inbox:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'approve4',
      text: '承認4',
      depends: ['inbox:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'approve5',
      text: '承認5',
      depends: ['inbox:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'approve6',
      text: '承認6',
      depends: ['inbox:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'approve7',
      text: '承認7',
      depends: ['inbox:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
    {
      key: 'approve8',
      text: '最終承認',
      depends: ['inbox:read'],
      options: [
        {
          value: 0,
          text: '権限なし',
        },
        {
          value: 1,
          text: '権限あり',
        },
      ],
    },
  ],
}
